// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-companie-avantaje-js": () => import("./../../../src/pages/companie/avantaje.js" /* webpackChunkName: "component---src-pages-companie-avantaje-js" */),
  "component---src-pages-companie-certificari-js": () => import("./../../../src/pages/companie/certificari.js" /* webpackChunkName: "component---src-pages-companie-certificari-js" */),
  "component---src-pages-companie-date-cifre-js": () => import("./../../../src/pages/companie/date-cifre.js" /* webpackChunkName: "component---src-pages-companie-date-cifre-js" */),
  "component---src-pages-companie-despre-noi-js": () => import("./../../../src/pages/companie/despre-noi.js" /* webpackChunkName: "component---src-pages-companie-despre-noi-js" */),
  "component---src-pages-companie-galerie-js": () => import("./../../../src/pages/companie/galerie.js" /* webpackChunkName: "component---src-pages-companie-galerie-js" */),
  "component---src-pages-companie-gdpr-js": () => import("./../../../src/pages/companie/gdpr.js" /* webpackChunkName: "component---src-pages-companie-gdpr-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industrii-js": () => import("./../../../src/pages/industrii.js" /* webpackChunkName: "component---src-pages-industrii-js" */),
  "component---src-pages-produse-accesorii-js": () => import("./../../../src/pages/produse/accesorii.js" /* webpackChunkName: "component---src-pages-produse-accesorii-js" */),
  "component---src-pages-produse-armaturi-robineti-js": () => import("./../../../src/pages/produse/armaturi-robineti.js" /* webpackChunkName: "component---src-pages-produse-armaturi-robineti-js" */),
  "component---src-pages-produse-fitinguri-js": () => import("./../../../src/pages/produse/fitinguri.js" /* webpackChunkName: "component---src-pages-produse-fitinguri-js" */),
  "component---src-pages-produse-flanse-js": () => import("./../../../src/pages/produse/flanse.js" /* webpackChunkName: "component---src-pages-produse-flanse-js" */),
  "component---src-pages-produse-js": () => import("./../../../src/pages/produse.js" /* webpackChunkName: "component---src-pages-produse-js" */),
  "component---src-pages-produse-tabla-profile-js": () => import("./../../../src/pages/produse/tabla-profile.js" /* webpackChunkName: "component---src-pages-produse-tabla-profile-js" */),
  "component---src-pages-produse-tevi-js": () => import("./../../../src/pages/produse/tevi.js" /* webpackChunkName: "component---src-pages-produse-tevi-js" */),
  "component---src-pages-servicii-js": () => import("./../../../src/pages/servicii.js" /* webpackChunkName: "component---src-pages-servicii-js" */)
}

