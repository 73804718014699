module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.tevi-laminate.ro"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-QSYETR36M5","UA-183261445-1"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"NIMFA","short_name":"NIMFA","start_url":"/","background_color":{"500":"#ED5154","700":"#D44649"},"theme_color":{"500":"#457186","700":"#043959"},"display":"minimal-ui","icon":"src/assets/images/nimfa_favicon.png"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
